<template>
  <en-table-layout
    :table-data="orderData"
    :span-method="spanMethod"
    ref="enTableLayout"
    class="order-table"
    :row-key="getRowKeys"
  >
    <template slot="table-columns">
      <el-table-column label="销售单据编号" width="200" prop="trade_sn">
        <template slot-scope="scope">
          <el-link type="primary" @click="toCardDeTail(scope.row)">{{
            scope.row.trade_sn
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        label="所属商城"
        prop="shop_name"
        show-overflow-tooltip
      />
      <el-table-column label="卡号数量" prop="card_num" width="110" />
      <el-table-column
        label="需关卡数量"
        prop="close_card_num"
        width="110"
        v-if="type == 'close' && disabled"
      >
        <template slot-scope="scope">
          {{ scope.row.close_card_num || 0 }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="关卡数量"
        v-if="type == 'open'"
        prop="close_card_num"
        width="110"
      /> -->
      <el-table-column
        label="已关卡数量"
        prop="close_card_num"
        width="110"
        v-if="type == 'open' && disabled"
      >
        <template slot-scope="scope">
          {{ scope.row.close_card_num || 0 }}
        </template>
      </el-table-column>
      <el-table-column
        label="销售人员"
        prop="sell_name"
        show-overflow-tooltip
      />
      <el-table-column label="单据日期" prop="create_time">
        <template slot-scope="scope">
          {{ scope.row.create_time | unixToDate }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" v-if="!disabled">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="delOrderItem(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </template>
  </en-table-layout>
</template>

<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import {
  changeDataList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";

export default {
  name: "selectSaleOrderList",
  components: {
    EnTableLayout,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    orderList: {
      default: () => [],
    },
    cache: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      default: "shop_trade_sell_list",
    },
    keyArr: {
      default: () => ["shop_name", "card_num", "close_card_num"],
    },
  },
  data() {
    return {
      orderData: this.orderList,
    };
  },
  watch: {
    orderList: {
      handler() {
        this.getOrderData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getOrderData();
  },
  methods: {
    getOrderData() {
      // console.log(this.keyName);
      this.orderData = changeDataList(
        this.orderList,
        this.keyName,
        this.keyArr
      );
    },
    spanMethod(opt) {
      let arr = [1, 2];
      if (this.disabled) arr = [1, 2, 3];
      return spanMethod(opt, arr);
    },
    delOrderItem(id) {
      let items = [].filter.call(this.orderList, (item) => item.id != id);
      this.$emit("change", items);
    },
    toCardDeTail(row) {
      this.cache && this.$store.dispatch("addVisitedViews", this.$route);
      const { id, trade_sn } = row;
      this.$router.push({
        name: "saleOrderDetail",
        query: {
          id,
          sn: trade_sn,
        },
      });
    },
    getRowKeys(row) {
      return row.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  /deep/ .en-table-header,
  /deep/ .en-table-layout-toolbar {
    padding: 0;
  }
}
</style>
